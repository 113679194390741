<template lang="pug">
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import moment from 'moment';
import convertCurrencyToSymbol from '@/utils/convertCurrencyToSymbol';
import pureMoneyFormat from '@/utils/currency';

const CalendarCheckOutline = () => import('vue-material-design-icons/CalendarCheckOutline');

export default {
  components: {
    CalendarCheckOutline,
  },
  data: () => ({
    moneyCurency: convertCurrencyToSymbol,
    moneyFormat: pureMoneyFormat,
    fetchPaymentsTimeout: null,
    dimensions: [],
    tableHeaders: [
      'profile.paymentHistory.idTableHeader',
      'profile.paymentHistory.dateTableHeader',
      'profile.paymentHistory.methodTableHeader',
      'profile.paymentHistory.statusTableHeader',
      'profile.paymentHistory.amountTableHeader',
    ],
    timerId: null,
    currentLimit: 20
  }),
  computed: {
    ...mapGetters({
      payments: 'personal/getPayments',
      paymentsMobile: 'personal/getPaymentsMobile',
      currentLanguage: 'user/getLanguage',
      paymentsCount: 'personal/getPaymentsCount',
    }),
    switchTBody() {
      return this.payments.length ? true : false;
    },
  },
  methods: {
    ...mapActions({
      fetchPayments: 'personal/fetchPayments'
    }),
    ...mapMutations({
    }),
    extractDate(ts) {
      const lang = this.currentLanguage.split('-')[0];
      moment.locale(lang);
      const onlyDate =
          moment(new Date(ts * 1000), 'MM DD YYYY')
          .format('L').toString().replace(/\//g, '.');
      const modifiedDate = this.currentLanguage === 'en-EN' ?
          `${onlyDate.split('.')[1]}-${onlyDate.split('.')[0]}-${onlyDate.split('.')[2]}`
          : onlyDate;

      return onlyDate != null ? modifiedDate : null;
    },
    extractTime(ts) {
      let time = null;
      if (ts) {
        const lang = this.currentLanguage.split('-')[0];
        moment.locale(lang);
        const onlyTime = new Date(ts * 1000).toString();
        if (onlyTime != null) {
          time = onlyTime.match(/[0-9]{2}\:[0-9]{2}/)[0];
        }
      }
      return time;
    },
    /**
     * метод для обработки событий скрола
     */
    handlePaymentHScroll() {
      if (this.paymentsCount >= this.currentLimit) {
        if (this.timerId != null) {
          clearTimeout(this.timerId);
          this.timerId = null;
        }

        this.timerId = setTimeout(async () => {
          this.timerId = null;
          let clientHeight = document.querySelector('.payments-history-wrapper').clientHeight;
          let scrollHeight = document.querySelector('.payments-history-wrapper').scrollHeight;
          let scrollTop = document.querySelector('.payments-history-wrapper').scrollTop;

          if (scrollHeight - clientHeight > 0) {
            if ((scrollTop / (scrollHeight - clientHeight)) > 0.80) {
              const limitToExtend = 10;
              this.currentLimit += limitToExtend;
              await this.fetchPayments(this.currentLimit);
              return;
            }
          }
        }, 300);
      }
    },
    /**
     * метод для установки - или минуса, в зависимости от операции
     * @returns {string}, "+|-"
     */
    setOperationSign(item) {
      return item == 'deposit' ? '+' : '-';
    }
  },
  async mounted() {
    try {
      const recurseFetchPayments = async () => {
        await this.fetchPayments(this.currentLimit);
        this.fetchPaymentsTimeout = setTimeout(recurseFetchPayments, 5000);
      };

      await recurseFetchPayments();
    } catch (error) {
      console.error(error);
    }
  },
  beforeDestroy() {
    clearTimeout(this.fetchPaymentsTimeout);
    clearTimeout(this.timerId);
    this.timerId = null;
  }
};
</script>

<style lang="scss" scoped>
</style>
