<template lang="pug">
  .payments-history-wrapper(@scroll="handlePaymentHScroll")
    table.table-wrapper
      thead.t-head
        tr.tr-row
          th.column(v-for="(item, index) of tableHeaders" :class="[`column${index+1}`]") {{ $t(item) }}

      tbody.t-body(v-if="switchTBody")
        tr.tr-col(v-for="item of payments")
          td.column.id {{ item.id }}

          td.column.datetime
            .datetime-container
              .date {{ extractDate(item.ts) }}
              .time {{ extractTime(item.ts) }}

          td.column.method {{ item.method }}

          td.column.status
            .status-container
              .status__dot(:class="{ 'pending': item.status_code !== 'success' && item.status_code !== 'fail', 'success': item.status_code === 'success', 'decline': item.status_code === 'fail' }")
              .status__title {{ item.status }}

          td.column.amount(:class="{ 'denied': item.status_code === 'fail' }") {{ setOperationSign(item.type) }} {{ moneyFormat(item.amount, item.currency_separator, item.currency_mirror_unit) }} {{ moneyCurency(item.currency_code) }}

        .mock-tbody(v-if="!switchTBody")
          .mock-tbody__content
            .icon-wrapper
              CalendarCheckOutline
            .description

    table.table-mobile(v-for="(arr, index) in paymentsMobile")
      thead.t-head
        tr.tr-row
          th.column.date {{ extractDate(index) }}
          th.column.time {{ $t('profile.paymentHistory.timeTableHeader') }}
          th.column.method {{ $t('profile.paymentHistory.methodTableHeader') }}
          th.column.status {{ $t('profile.paymentHistory.statusTableHeader') }}
          th.column.amount {{ $t('profile.paymentHistory.amountTableHeader') }}

      tbody.t-body(v-if="switchTBody" v-for="item of arr")
        tr.tr-col
          td.column.id {{ item.id }}

          td.column.datetime
            .datetime-container
              .time {{ extractTime(item.ts) }}

          td.column.method
            .method-container
              .method__dot(:class=`{ 'pending': item.status_code !== 'success' && item.status_code !== 'fail',
                                        'success': item.status_code === 'success',
                                        'decline': item.status_code === 'fail' }`)
              .method__title {{ item.method }}

          td.column.status
            .status-container
              .status__dot(:class=`{ 'pending': item.status_code !== 'success' && item.status_code !== 'fail',
                                        'success': item.status_code === 'success',
                                        'decline': item.status_code === 'fail' }`)
              .status__title {{ item.status }}

          td.column.amount(:class="{ 'denied': item.status_code === 'fail' }") {{ setOperationSign(item.type) }} {{ moneyFormat(item.amount, item.currency_separator, item.currency_mirror_unit) }} {{ moneyCurency(item.currency_code) }}
</template>

<script>
import PaymentsHistory from '../PaymentsHistory.vue';

export default {
  extends:PaymentsHistory
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/personal/payments-history_sw.scss";
</style>
